import { ThemeOptions } from '@material-ui/core';
import { Object } from 'ts-toolbelt';

export const themeOverridesMap: Record<
  string,
  Object.Partial<ThemeOptions, 'deep'>
> = {
  QUIGLEY: {
    palette: {
      primary: {
        main: '#12505b',
        dark: '#12505b',
      },
      secondary: {
        main: '#13505B',
      },
      success: {
        main: '#30C7C0',
      },
    },
  },
  HK_DIGITAL: {
    // #6A0966
    // #B2469A
    // #310134
    //  #141615
    //  #FFDE01
    //  #9A999A
    //  #D4D4D4
    palette: {
      primary: {
        main: '#6A0966',
        dark: '#B2469A',
      },
      secondary: {
        main: '#310134',
      },
      success: {
        main: '#096A0D',
        light: '#FFDE01',
      },
      error: {
        main: '#B24664',
      },
      info: {
        main: '#FFDE01',
      },
      warning: {
        main: '#B2469A',
      },
      text: {
        primary: '#141615',
      },
      background: {
        default: '#D4D4D4',
      },
    },
  },
};
