import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const AuthContext = createContext({ session: null, user: null, token: null });

const AuthProvider = ({ ...props }) => {
  const [state, setState] = useState({
    session: null,
    user: null,
    token: null,
    loading: true,
  });
  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        setState({
          session,
          user,
          loading: false,
        });
      } catch (e) {
        console.log(e);
        setState(s => ({ ...s, loading: false }));
      }
    })();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        session: state.session,
        user: state.user,
        token: state.token,
        loading: state.loading,
        setAuth: setState,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
