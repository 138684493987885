import { REACT_APP_APP_CLIENT_ID, REACT_APP_USER_POOL_ID } from './constants';

const awsconfig = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: REACT_APP_APP_CLIENT_ID,
  },
};

export default awsconfig;
