import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppProviders from './context';
import App from './App';

ReactDOM.render(
  <AppProviders>
    <CssBaseline />
    <App />
  </AppProviders>,
  document.querySelector('#root'),
);
