import React, { useEffect, Suspense } from 'react';
import { useAuth } from 'context/authContext';
import FullPageSpinner from 'components/FullPageSpinner';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const { loading, session } = useAuth();

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);
  return (
    <Suspense fallback={<FullPageSpinner />}>
      {loading && <FullPageSpinner />}
      {session ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

export default App;
